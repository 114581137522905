import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-Home',
  templateUrl: './Home.component.html',
  styleUrls: ['./Home.component.scss']
})
export class HomeComponent implements OnInit {
  options: google.maps.MapOptions = {
    // 33.8736814,-117.9059324
    center: {lat: 33.87368255402309, lng: -117.90374457836151},
    zoom: 17
  };
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [
    {
      lat: 33.87368255402309, lng: -117.90374457836151
    }
  ];

  // apiLoaded: Observable<boolean>;
  constructor(httpClient: HttpClient) {
    
   }
  markers: marker[] = [
	  {
      //Mythic
		  lat: 34.0567361,
		  lng: -118.2459622,
		 
	  }
  ]
  ngOnInit() {
  }

  addMarker(event: google.maps.MapMouseEvent) {
    
      this.markerPositions.push(event.latLng.toJSON());
      console.log(this.markerPositions)
    }

}

interface marker {
	lat: number;
	lng: number;

}

