import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiserviceService {
baseUrl = 'https://www.mythicbjj.com/'
// baseUrl = 'https://localhost:5001/'
constructor(private http: HttpClient) { }
  getPhotos() {
    return this.http.get(this.baseUrl + 'api/photos');
  }

  sendContact(body: any) {
    return this.http.post<any>(this.baseUrl + 'api/forms/contactUs', body);
  }

}


