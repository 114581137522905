<div class="text-center my-4" style="margin-left: 30%"> 
  <h2>Photo Gallery</h2>
</div>

<!-- <div class="container">
  <div class="row">
    <div *ngFor="let item of photos"  class="col-md-4">
      <a href="{{item.url}}" class="card" style="width: 18rem;">
        <img class="card-img-top" src="{{item.url}}" alt="Card image cap">
      
      </a>
    </div>
  </div>
</div> -->

<div class="container">
  <div class="grid-container">
    <div  *ngFor="let item of photos">
      <a href="{{item.url}}" target="_blank">
        <img  class='grid-item grid-item-1' src="https://res.cloudinary.com/dvexkxjxk/image/upload/c_scale,w_280/{{item.publicId}}.jpg" alt="{{item.fileName}}">
      </a>
      
    </div>
  </div>
</div>

<!-- c_scale,w_310 -->