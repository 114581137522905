
<nav class="navbar navbar-expand-lg navbar-dark" [ngClass]= "url ? 'navbar--else' : 'navbar--home'">
  <div class="container-fluid">
    <div class="nav--flag">
      <img src="https://res.cloudinary.com/dvexkxjxk/image/upload/v1613357056/nav-model_mpsso8.png" alt="">
    </div>
    <button class="navbar-toggler ml-auto" type="button" data-bs-toggle="collapse" (click)="isCollapsed = !isCollapsed" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span #toggleButton  class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav" [ngClass]="{ collapse: isCollapsed, 'navbar-collapse': true }">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href=”#!” routerLinkActive="active" routerLink="/gallery">Gallery</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href=”#!” routerLinkActive="active" routerLink="/bookings">Classes</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href=”#!” routerLinkActive="active" routerLink="/contactus" >Contact</a>
        </li>
      </ul>
    </div>
  </div>
</nav>