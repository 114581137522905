<div class="banner">
  <div class="banner--title">
    <h1>Mythic BJJ</h1>
    <h3>Brazilian Jiu-Jitsu, Eskrima, Wrestling</h3>
  </div>
</div>

<div class="programs container mt-5">
  <h2>Class Programs</h2>
  <div class="row my-5">
    <div class="col-lg-4 d-flex align-items-stretch">
      <div class="card" >
        <div class="card-body">
          <h5 class="card-title text-center">Brazilian Jiu-Jitsu </h5>
          
          <p class="card-text">Brazilian Jiu-Jitsu is a self-defense martial art and combat sport 
            based on grappling. It is practiced, both with a gi and with out (nogi) with the intention 
            of controlling a resisting opponent in ways that force them to submit.</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 d-flex align-items-stretch">
      <div class="card" >
        <div class="card-body">
          <h5 class="card-title text-center">Eskrima</h5>
          
          <p class="card-text">AKA Kali or Arnis. The traditional martial art of the Philippines, which emphasize weapon based fighting with sticks, knives, and various improvised weapons as well as empty hand.</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 d-flex align-items-stretch">
      <div class="card" >
        <div class="card-body">
          <h5 class="card-title text-center">Wrestling</h5>
          
          <p class="card-text">Wrestling is a combat sport involving grappling techniques such as clinch fighting, throws, takedowns, and pins. The focus is to gain and maintain a superior position over the opponent.</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 d-flex align-items-stretch">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title text-center">Firebirds (Youth) Program</h5>
          
          <p class="card-text">The Firebird program is focused on youth ages 5 - 15. The program integrates physical education and self defense to develop motor skills and a keen self awareness.  The Firebird program includes Youth BJJ class, Youth Wrestling, and Eskrima.</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 d-flex align-items-stretch">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title text-center">Muay Thai</h5>
          
          <p class="card-text">Muay Thai is a striking art known as the "art of eight limbs". It is characterized by the combined use of fists, elbows, knees, and shins.</p>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div class="position-relative" style="margin: 0 0.8em;">
  <div class="trainers">
    <div class="container">
      <h2 class="mb-3">Our Trainers</h2>
      <div class="row">
        <div class="col-md-6 left-caption d-none d-md-block">
          <img src="../../assets/imgs/mythic trainers.jpg" alt="">
        </div>
        <div class="col-md-6">
          <carousel [noPause]="false" class="car mx-4">
            <slide>
          
              <div class="carousel-caption">
                <h3>Jeff Nolasco</h3>
                <p>Jeff Nolasco is a 1st degree black belt in Brazilian Jiu-Jitsu. He began his training in 2005 primarily to lose weight. 10 years and over 50 pounds lighter he received his black belt in 2015 from Mauricio Mariano at the Tinguinha Brazilian Jiu-Jitsu Academy. In 2009 Jeff began teaching the art and has continued sharing the art of Jiu-Jitsu through UFC Gym. During this time Jeff has also competed and medaled in several BJJ tournaments put on by the IBJJ, NABJJF, and JJWL. In 2010, Jeff traveled to Brazil to completely immerse himself in the art. It was at this point that he knew that training was no longer a hobby, it was a way of life. Amidst all the events of 2020, it provided perfect time to open Mythic BJJ to share the art that had changed his life for the better.</p>
              </div>
            </slide>
            <slide>
          
              <div class="carousel-caption ">
                <h3>Randy Louie</h3>
                <p>Randy Louie a.k.a. “Pad-Man” has been training & teaching in Kickboxing & Martial Arts for over 30+ years. He recieved his Muay Thai Training Certificates by the WMC (World Muay Thai Council) & Ministry of Education of Thailand at the Muay Thai Institute, in Rangsit Thailand & is a 4th Degree Black Belt in Kuk Sool Won / Hapkido. He also has extensive experience in Boxing, Kenpo Karate, Tae Kwon Do, & Judo. His most influential Muay Thai instructors are Master Mongil Huh - Kyuk Too Ki “Korean Kickboxing”, Ajarn Niwatchai Sridej, Kru Sawat TienSoPra & Kru Dang Pudbaudong - Amnuay Muay Thai Institute, Rangsit Thailand & Kru Chun & Dam – Phuket Muay Thai Camp, Phuket Thailand. His prior fight & training experience in Martial Arts & Muay Thai has made Randy a well versed trainer in which he incorporates these skills into his students and fighters.</p>
              </div>
            </slide>
            <slide>
          
              <div class="carousel-caption ">
                <h3>Jamie Ocampo</h3>
                <p>Started Brazilian Jiu Jitsu with Professor Carlos Enrique “Caique” Elias in 2003 
                  acquired my blue belt from Caique in 2004 
                  1st place at the 2005 Copa Pacifica tournament as a blue belt at Masters Super Heavy 
                  1st place at the 2006 North American tournament as a blue belt at Masters Super Heavy 
                  Earned my purple belt in 2006 from Professor Caique 
                  2nd place at the 2007 North American tournament as a purple belt at Adult Super Super Heavy 
                  2nd place at the 2008 North American tournament as a purple belt at Masters Light Heavy 
                  1st place at the 2008 American Nationals tournament as a purple belt at Masters Super Heavy 
                  Taught the adult and kids Brazilian Jiu Jitsu classes at Yobe Jiu Jitsu in Lombard Illinois while Professor Siosani Shivigari was away from the academy in December 2015 -January 2016
                  Brazilian Jiu Jitsu Coach at UFC Gym La Mirada from November 2016 to March 2020
                  Earned my brown belt in 2017 from Professor Jeffrey Nolasco
                  Brazilian Jiu Jitsu Coach at Mythic Brazilian Jiu Jitsu from October 2020 to Present
                  Jiu Jitsu World League Gold Medalist</p>
              </div>
            </slide>
            
          
          
          </carousel>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="contact" class="contact-us pb-3">
  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <img class="card-img-top" src="https://res.cloudinary.com/dvexkxjxk/image/upload/v1615408009/MythicBJJ-LOGO_white-transparent_back_vqgfau.png" alt="">
          <div class="card-body">
            <h4>Mythic BJJ</h4>
            <p>1342 E Chapman Ave, Fullerton, CA 92831</p>
            <p>
              <a href="tel:714-519-6550">+1 714-519-6550</a>
            </p>
            <p>
              <a href="mailto:mythicbjj@gmail.com">mythicbjj@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6 my-4">
        
          <google-map [options]="options" (mapClick)="addMarker($event)">
            <map-marker *ngFor="let markerPosition of markerPositions"
              [position]="markerPosition"
              [options]="markerOptions"></map-marker>
          </google-map>
       
      </div>
    </div>
  </div>
</div>

