
<div class="text-center my-4" style="margin-left: 30%"> 
    <h2>Class Information</h2>
</div>
<script src="https://widgets.mindbodyonline.com/javascripts/healcode.js" type="text/javascript"></script>
<div class="container">
    <healcode-widget data-type="schedules" data-widget-partner="object" data-widget-id="c9180796c52c" data-widget-version="1" ></healcode-widget>



</div>