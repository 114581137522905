import { Component, OnInit } from '@angular/core';
import { ApiserviceService } from '../_Services/apiservice.service';

@Component({
  selector: 'app-Gallery',
  templateUrl: './Gallery.component.html',
  styleUrls: ['./Gallery.component.scss']
})
export class GalleryComponent implements OnInit {
  photos: any;
  constructor(private photoService: ApiserviceService) { }

  ngOnInit() {
    

    this.photoService.getPhotos().subscribe(data => {
      console.log(data);
      this.photos = data;
    })
  }

}
