import { Component } from '@angular/core';
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router'
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'Mythic BJJ';
  public showOverlay = true;
  

  constructor(private router: Router) {

    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){
        console.log(event.urlAfterRedirects);
          gtag('config', 'UA-192158796-1', 
                {
                  'page_path': event.urlAfterRedirects
                }
               );
       }
    }
 )

    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    })
  }

  navigationInterceptor(event: RouterEvent): void {
  
  }
}
