import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Contact } from '../_models/contact'
import { ApiserviceService } from '../_Services/apiservice.service';

@Component({
  selector: 'app-ContactUs',
  templateUrl: './ContactUs.component.html',
  styleUrls: ['./ContactUs.component.scss']
})
export class ContactUsComponent implements OnInit {
  SITE_KEY = '6LeMhXsaAAAAAJGLeSpf_zsNhZkZm8edYQwKXIwK';
  contactForm: any;
  submitted = false;
  completed = false;
  constructor(private formBuilder: FormBuilder, private httpService: ApiserviceService) { 
    this.contactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email:  ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      recaptchaReactive: ['']
    })
  }
  resolved(captchaResponse: string) {
    console.log(captchaResponse);

  }
  
  ngOnInit() {
    
  }

  onSubmit() {
    this.submitted = true;
    this.completed = false;
    // stop here if form is invalid
    console.log(this.contactForm)
    if (this.contactForm.invalid) {

        return;
    }
    this.httpService.sendContact(this.contactForm.value).subscribe({
      next: data => {
        
        console.log(data);
        if(data) {
          console.log("Successfull");
          this.completed = true;
        }
      },
      error: error => {
        console.log('There was an error: ' + error);
      }
    })
    
    this.submitted = false;
    this.contactForm.reset();
    // display form values on success
    
}
  get f() { return this.contactForm.controls; }

}
