import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './Home/Home.component';
import { MindBodyComponent } from './MindBody/MindBody.component';
import { GalleryComponent } from './Gallery/Gallery.component';
import { ContactUsComponent } from './ContactUs/ContactUs.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'contact', component: HomeComponent},
  {path: 'bookings', component: MindBodyComponent},
  {path: 'gallery', component: GalleryComponent},
  {path: 'contactus', component: ContactUsComponent}  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
