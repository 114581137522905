import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { HttpClientModule, HttpClientJsonpModule  } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";




import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './Navigation/Navigation.component';
import { HomeComponent } from './Home/Home.component';
import { MindBodyComponent } from './MindBody/MindBody.component';
import { GalleryComponent } from './Gallery/Gallery.component';
import { ContactUsComponent } from './ContactUs/ContactUs.component';

@NgModule({
  declarations: [					
    AppComponent,
      NavigationComponent,
      HomeComponent,
      MindBodyComponent,
      GalleryComponent,
      ContactUsComponent
   ],
  imports: [
    BrowserModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyCBaifx6pVAv213P0l9OGNTjlLlZaBnYT8'
    // }),
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    CarouselModule.forRoot(),
    HttpClientModule,
    GoogleMapsModule,
    RecaptchaModule,
    ReactiveFormsModule,
    HttpClientJsonpModule 
    

  ],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
