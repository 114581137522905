import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { HomeComponent } from '../Home/Home.component';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-Navigation',
  templateUrl: './Navigation.component.html',
  styleUrls: ['./Navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  @ViewChild('toggleButton') toggleButton: any;
  @ViewChild('navbar-collapse') menu: any;
  isCollapsed = true;
  url = ""
  isHome = false;
  constructor(private router: Router, private renderer: Renderer2, private viewportScroller: ViewportScroller) { 
    router.events.subscribe((route) => {
      
      if(route instanceof NavigationEnd) {
        this.url = route.url;
        console.log(this.url + ' ' + route.url);
        if(this.url === '/contact' ){
          console.log('true');
          this.onClickScroll('contact');
        }
        if(this.url && this.url.length > 0) {
          this.url = this.url.slice(1);
          this.isHome = true;
          console.log(this.url);
        }
      }
    });
    this.renderer.listen('window', 'click',(e:Event)=>{
      /**
       * Only run when toggleButton is not clicked
       * If we don't check this, all clicks (even on the toggle button) gets into this
       * section which in the result we might never see the menu open!
       * And the menu itself is checked here, and it's where we check just outside of
       * the menu and button the condition abbove must close the menu
       */
      console.log(e.target);
     if(e.target !== this.toggleButton?.nativeElement && e.target!==this.menu?.nativeElement){
         this.isCollapsed=true;
      }
    });
  }

  onClickScroll(elementId: string): void {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  ngOnInit() {
  }

}
