<div class="jumbotron">
  <div class="center">
    <h1>Contact Us</h1>
  </div>
</div>

<div class="container my-4">
  <div class="row">
    <div class="col-md-6">

<form novalidate (ngSubmit)="onSubmit()" [formGroup]="contactForm">
  <div class="form-group">
    <label for="">First Name</label>
    <input type="text" class="form-control" id="fName" formControlName="firstName">
</div>
  <div class="form-group">
    <label for="">Last Name</label>
    <input type="text" class="form-control" id="lName" formControlName="lastName">
</div>
  <div class="form-group">
    <label for="">Email</label>
    <input type="email" class="form-control" id="email" aria-describedby="emailHelp" required formControlName="email">
    <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
    <div *ngIf="submitted"  style="color: red"  >
      <p *ngIf="f.email.errors?.required || f.email.errors?.required">Please Enter Valid Email</p>
    </div>
  </div>
  <div class="form-group">
    <label for="phone"  >Phone Number</label>
    <input type="Telephone" class="form-control" id="phoneNumber" formControlName="phoneNumber">
    <div *ngIf="submitted"  style="color: red"  >
      <p *ngIf="f.phoneNumber.errors?.required || f.phoneNumber.errors?.required === undefined">Please Enter Valid Phone Number</p>
    </div>
  </div>


  <div class="mb-4"> 
    <re-captcha (resolved)="resolved($event)" 
                formControlName="recaptchaReactive" 
                siteKey="6LeMhXsaAAAAAJGLeSpf_zsNhZkZm8edYQwKXIwK">  
    </re-captcha>
  </div>
  <div *ngIf="submitted"  style="color: red"  >
    <p *ngIf="f.recaptchaReactive.errors?.required || f.recaptchaReactive.errors?.required === undefined">Please Click reCAPTCHA checkbox</p>
  </div>
  <button type="submit" class="btn btn-primary mr-4">Submit</button>
  <span *ngIf="completed" style="color: #e26b26">Submitted Successfully</span>
</form>

    </div>
    <div class="col-md-6">
      <div class="card" style="">
        <img src="https://res.cloudinary.com/dvexkxjxk/image/upload/v1615408009/MythicBJJ-LOGO_white-transparent_back_vqgfau.png" class="card-img-top" alt="...">
        <div class="card-body ">
          <h4 class="">Mythic BJJ</h4>
          <p>1342 E Chapman Ave, Fullerton, CA 92831
          <p>
            <a href="tel:714-519-6550" data-wpel-link="internal">+1 714-519-6550</a>
          </p>
          <p>
            <a href="mailto:">mythicbjj@gmail.com</a>
          </p>
          <p></p>
        </div>
      </div>
    </div>
  </div>
</div>

