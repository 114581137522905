import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-MindBody',
  templateUrl: './MindBody.component.html',
  styleUrls: ['./MindBody.component.css']
})

export class MindBodyComponent implements OnInit {
  url = "https://widgets.mindbodyonline.com/javascripts/healcode.js"
  loadAPI: any;
  constructor() { 
    this.buttonClicked();
  }
  public buttonClicked() {
    this.loadAPI = new Promise((resolve) => {
        console.log('resolving promise...');
        this.loadScript();
    });
}

public loadScript() {
    console.log('preparing to load...')
    let node = document.createElement('script');
    node.src = this.url;
    node.type = 'text/javascript';
    node.async = true;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
}
  ngOnInit() {
  }

}
